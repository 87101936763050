
import {
  defineComponent, onMounted, PropType, toRefs, unref,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import Loader from '@/components/ui/Loader.vue';

import { assignmentRun } from '@/services/api/lxp-assignment';

import { IAssignment } from '@/domains/assignment';
import { AtomType } from '@/domains/atom';

import Names from '@/plugins/vue-router/routeNames';
import { useFeatureFlag } from '@/plugins/feature-flags';

/**
 * Компонент запуска назначенной программы
 */
export default defineComponent({
  name: 'AssignmentRun',

  components: {
    Loader,
  },

  props: {
    assignmentId: {
      type: Number as PropType<IAssignment['assignmentUserId']>,
      required: true,
    },

    catalogueAtomMainId: {
      type: String as PropType<IAssignment['catalogueAtomMainId']>,
      required: true,
    },
  },

  setup(props) {
    const { assignmentId, catalogueAtomMainId } = toRefs(props);

    const isAtomPublicationAvailable = useFeatureFlag('atom_publication');
    const isCoursePublicationAvailable = useFeatureFlag('atom_publication_course');

    const router = useRouter();

    /**
     * Переход на страницу запущенной программы
     * @param {number} playerSessionId - id запущенной сессии прохождения программы
     */
    const goToSession = ({
      assignmentUserId,
      playerSessionId,
      atomType,
    }: {
      assignmentUserId: number,
      playerSessionId: number,
      atomType: AtomType,
    }) => {
      const isTrack = atomType === AtomType.TRACK;

      const name = (isAtomPublicationAvailable.value && !isTrack) || (isCoursePublicationAvailable.value && isTrack)
        ? Names.R_APP_LEARNING_SESSION_GATEWAY
        : Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP;

      const params = {
        assignmentId: String(assignmentUserId),
        trackSessionId: String(playerSessionId),
        playerSessionId: String(playerSessionId),
        atomType,
      };

      return router.replace({
        name,
        params,
      });
    };

    /**
     * Запуск назначения
     */
    const runAssignment = async () => {
      try {
        const { playerSessionId, atomType } = await assignmentRun({
          paths: {
            assignmentUserId: unref(assignmentId),
          },
          data: {
            catalogueAtomMainId: unref(catalogueAtomMainId),
          },
        });

        await goToSession({
          assignmentUserId: assignmentId.value,
          playerSessionId,
          atomType,
        });
      } catch (e: any) {
        console.error(e);

        await router.back();
      }
    };

    onMounted(() => {
      runAssignment();
    });
  },
});

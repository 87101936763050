<template>
  <VOverlay
    absolute
    overlay
    color="tt-light-mono-0"
    opacity="1"
  >
    <VProgressCircular
      indeterminate
      color="tt-light-blue vibrant"
    />
  </VOverlay>
</template>
<script>
export default {
  name: 'Loader',
};
</script>
